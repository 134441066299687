<template>
  <div class="buddha-tablet-info">
    <div class="buddha-tablet-list">
      <div class="buddha-tablet-item" v-for="(t, i) in list" :key="i">
        <div class="buddha-tablet-title" v-if="edit">牌位{{i + 1}}<span class="txt-btn" @click="showEditTablet(i)">修改</span><span class="txt-btn danger" @click="delTablet(i)" v-if="!part">删除</span></div>
        <div class="buddha-tablet-title" v-else-if="list.length > 1">牌位{{i + 1}}</div>
        <div class="tablet-info-item" v-if="temp.cat == 3">
          <div class="buddha-tablet-label">类型</div>
          <div class="buddha-tablet-text">{{getBuddhaSubTypeName(temp.fs_type, t.type)}}</div>
        </div>
        <div class="tablet-info-item" v-if="t.cat == 2">
          <div class="buddha-tablet-label">关系</div>
          <div class="buddha-tablet-text">{{buddhism.getBuddhismRelationText(t.relation)}}</div>
        </div>
        <div class="tablet-info-item" v-else>
          <div class="buddha-tablet-label">对象</div>
          <div class="buddha-tablet-text">{{buddhism.getBuddhismObjectTypeText(t.user_type)}}</div>
        </div>
        <div class="tablet-info-item" v-if="t.user_type == 2">
          <div class="buddha-tablet-label">公司/团体名称</div>
          <div class="buddha-tablet-text">{{t.name || (t.personList && t.personList.length > 0  ? t.personList[0].name : '')}}</div>
        </div>
        <template v-else>
          <div class="tablet-info-item" v-if="t.personList && t.personList.length > 0">
            <div class="buddha-tablet-label">牌位</div>
            <div class="buddha-tablet-text">
              <div class="buddha-text-item" v-for="(person, i) in t.personList" :key="i">
                <span class="text-item-span" v-if="person.relation">{{person.relation}}</span>
                <span class="text-item-span">{{person.name}}</span>
                <div class="flex-grow">
                  <div class="buddha-text-item">
                    <span class="text-item-span" v-if="person.birth">{{person.die ? '生于' : '生日 '}}{{person.birthDateType == 2 ? `农历${getLunarDay(new Date(person.birth), true, true)}` : `公历 ${person.birth}`}}</span>
                    <span class="text-item-span" v-if="person.birthTime || person.time">{{buddhism.getTimeText(person.birthTime || person.time)}}</span>
                  </div>
                  <div class="buddha-text-item" v-if="person.die">
                    <span class="text-item-span">卒于{{person.dieDateType == 2 ? `农历${getLunarDay(new Date(person.die), true, true)}` : `公历${person.die}`}}</span>
                    <span class="text-item-span" v-if="person.dieTime">{{buddhism.getTimeText(person.dieTime)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tablet-info-item" v-if="t.posterityList && t.posterityList.length > 0">
            <div class="buddha-tablet-label">阳上</div>
            <div class="buddha-tablet-text">
              <div class="buddha-text-item" v-for="(posterity, i) in t.posterityList" :key="i">
                <span class="text-item-span" v-if="posterity.relation">{{posterity.relation}}</span>
                <span class="text-item-span">{{posterity.name}}</span>
                <span class="text-item-span" v-if="posterity.birth">生日 {{posterity.birth}} {{getLunarDay(new Date(posterity.birth), true)}}</span>
              </div>
            </div>
          </div>
        </template>
        <div class="tablet-info-item">
          <div class="buddha-tablet-label">地址</div>
          <div class="buddha-tablet-text">{{(t.area || []).join("") + (t.address || '无')}}</div>
        </div>
      </div>
    </div>
    <a-button icon="plus-circle" @click="showAddTablet" v-if="edit && !part">添加牌位</a-button>
    <a-modal
      :title="index === null ? '添加牌位' : '编辑牌位'"
      :mask-closable="false"
      :visible="dv"
      :width="800"
      :body-style="{paddingBottom: '69px'}"
      @cancel="onClose"
      @ok="confirmTablet"
      v-if="edit">
      <a-form-model
        class="tablet-form"
        ref="tabletForm"
        :model="form"
        :rules="rules">
        <a-form-model-item label="选择类型" prop="type" v-if="temp.cat == 3">
          <a-radio-group :options="typeList" v-model="form.type"></a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="关系" prop="relation" v-if="relation">
          <a-select v-model="form.relation" :options="relationList" placeholder="请选择关系"
                    style="width: 100%"></a-select>
        </a-form-model-item>
        <a-form-model-item label="对象" prop="user_type" v-else-if="temp.cat == 3">
          <a-radio-group v-model="form.user_type" :options="objectTypeList"></a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="公司/团体名称" prop="name" v-if="userType == 2 || form.user_type == 2">
          <a-input v-model="form.name" placeholder="公司/团体名称"></a-input>
        </a-form-model-item>
        <template v-else>
          <a-form-model-item label="牌位" prop="personList" v-if="personNumber > 0">
            <div class="tablet-person-item" v-for="(p, i) in form.personList" :key="i">
              <form-area
                ref="personForm"
                class="tablet-person-form"
                layout="inline"
                hide-btn
                direct
                :items="tabletConfig.personItems"
                :entity="p">
                <template v-slot:date="{form: config, control}">
                  <a-input-group compact>
                    <a-select v-model="config[`${control.key}DateType`]" :options="dateTypeOptions" style="width: 72px;"></a-select>
                    <a-date-picker
                      placeholder="请选择日期"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                      :disabled-date="disabledDate"
                      v-model="config[control.key]"
                      style="width: 180px;"
                      v-if="config[`${control.key}DateType`] == 1"/>
                    <a-date-picker
                      :show-today="false"
                      placeholder="请选择日期"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                      :disabled-date="disabledDate"
                      v-model="config[control.key]"
                      style="width: 180px;" v-else>
                      <template slot="dateRender" slot-scope="current">
                        <div class="ant-calendar-date" style="width: auto">{{ getLunarDay(current.toDate()) }}</div>
                      </template>
                      <div class="person-date-show">
                        <div class="person-date-text" v-if="config[control.key]">{{getLunarDay(new Date(config[control.key]), true, true)}}</div>
                        <div class="person-date-text person-date-place" v-else>请选择日期</div>
                        <a-icon type="calendar" class="person-date-icon" />
                      </div>
                    </a-date-picker>
                  </a-input-group>
                </template>
              </form-area>
              <a-icon class="tablet-person-remove" type="close-circle" theme="filled" @click="removeItem(i, 'person')" v-if="!part"/>
            </div>
            <a-button type="primary" @click="addItem('person')" v-show="form.personList.length < personNumber" v-if="!part">
              <a-icon type="plus-circle" />
              <span>添加牌位</span>
            </a-button>
          </a-form-model-item>
          <a-form-model-item label="阳上" prop="posterityList" v-if="posterityNumber > 0">
            <div class="tablet-person-item" v-for="(p, i) in form.posterityList" :key="i">
              <form-area
                ref="posterityForm"
                class="tablet-person-form"
                layout="inline"
                hide-btn
                direct
                :items="tabletConfig.posterityItems"
                :entity="p">
              </form-area>
              <a-icon class="tablet-person-remove" type="close-circle" theme="filled" @click="removeItem(i, 'posterity')" v-if="!part"/>
            </div>
            <a-button type="primary" @click="addItem('posterity')" v-show="form.posterityList.length < posterityNumber" v-if="!part">
              <a-icon type="plus-circle" />
              添加阳上
            </a-button>
          </a-form-model-item>
        </template>
        <a-form-model-item label="所在地区" prop="area">
          <a-cascader :options="city" placeholder="请选择所在地区" v-model="form.area"/>
        </a-form-model-item>
        <a-form-model-item label="详细地址" prop="address">
          <a-input v-model="form.address" placeholder="请输入详细地址"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      :title="mt == 'person' ? '添加牌位' : '添加阳上'"
      :maskClosable="false"
      :closable="false"
      :destroyOnClose="true"
      :width="480"
      @ok="modalConfirm"
      v-model="mv"
      v-if="edit">
      <form-area
        ref="personForm"
        hide-btn
        label-align="left"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 18}"
        :items="tabletConfig[`${mt}Items`]"
        :entity="person">
      </form-area>
    </a-modal>
  </div>
</template>

<script>
    import moment from 'moment';
    import {clone, getRadioOptionsFromSelect} from "../../common/js/tool";
    import * as buddhism from "../../common/buddha/buddhism";
    import {getLunarDay} from "../calendar/lunar";
    import {getBuddhaSubTypeName} from "../../common/buddha/type";
    import city from "../../common/constant/city";

    export default {
        name: "buddha-tablet",
        model: {
            event: "change"
        },
        props: {
            value: Array,
            temp: Object, // 佛事/法会对象
            typeList: Array,
            type: String, //已选择的佛事/法会种类
            userType: Number, // 已选择对象类型
            edit: {
                type: Boolean,
                default: true
            },
            // 部分修改
            part: Boolean
        },
        data() {
            return {
                buddhism,
                city,
                list: [],
                objectTypeList: getRadioOptionsFromSelect(buddhism.buddhismObjectTypeList),
                relationList: buddhism.getBuddhismTypeOptions(buddhism.buddhismRelationList),
                form: {
                    personList: [],
                    posterityList: []
                },
                person: {},
                dateTypeOptions: [
                    {value: 1, label: '公历'},
                    {value: 2, label: '农历'},
                ],
                rules: {
                    type: [{required: true, message: '请选择类型', trigger: 'change'}],
                    user_type: [{required: true, message: '请选择对象', trigger: 'change'}],
                    relation: [{required: true, message: '请选择关系', trigger: 'change'}],
                    personList: [
                        {required: true, message: '请添加牌位', trigger: 'change'},
                        {
                            validator: (rule, value, callback) => {
                                if (value && value.length > 0) {
                                    Promise.all(this.$refs.personForm.map(f => f.handleConfirm())).then(() => {
                                        callback();
                                    }).catch(() => {
                                        callback(new Error("请正确填写牌位信息"))
                                    })
                                } else {
                                    callback(new Error('请至少添加一个牌位'));
                                }
                            },
                            trigger: 'change'
                      }
                    ],
                    posterityList: [
                        {required: true, message: '请添加阳上', trigger: 'change'},
                        {
                            validator: (rule, value, callback) => {
                                if (value && value.length > 0) {
                                    Promise.all(this.$refs.posterityForm.map(f => f.handleConfirm())).then(() => {
                                        callback();
                                    }).catch(() => {
                                        callback(new Error("请正确填写阳上信息"))
                                    })
                                } else {
                                    callback(new Error('请至少添加一个阳上'));
                                }
                            },
                            trigger: 'change'
                        }
                    ],
                    area: [{required: true, message: '请选择所在地区', trigger: 'change'}],
                    address: [{required: true, message: '请输入详细地址', trigger: 'blur'}],
                    username: [{required: true, message: '请输入府上名称', trigger: 'blur'}],
                    contact_person: [{required: true, message: '请输入联系人', trigger: 'blur'}],
                    tel: [{required: true, message: '请输入联系电话', trigger: 'blur'}],
                },
                dv: false,
                mv: false,
                mt: 'person',
                mi: null,
                index: null
            }
        },
        computed: {
            // 是否需要选择关系
            relation() {
                return buddhism.hasRelation({
                    cat: this.temp.cat,
                    type: this.type || this.form.type
                });
            },
            // 牌位信息填写配置
            tabletConfig() {
                return buddhism.getTabletConfig({type: this.temp.fs_type, relation: this.form.relation}, this.relation);
            },
            personNumber() {
                let conf = this.tabletConfig;
                return conf && conf.person ? conf.person.num : 0;
            },
            posterityNumber() {
                let conf = this.tabletConfig;
                return conf && conf.posterity ? conf.posterity.num : 0;
            }
        },
        watch: {
            value() {
                this.init();
            },
            personNumber() {
                this.setList("person");
            },
            posterityNumber() {
                this.setList("posterity");
            },
        },
        created() {
            this.init();
        },
        methods: {
            getLunarDay,
            getBuddhaSubTypeName,
            disabledDate(current) {
                return current && current >= moment().endOf('day')
            },
            delTablet(i) {
                this.list.splice(i, 1);
            },
            showEditTablet(i) {
                const form = clone(this.list[i]);
                this.form = form;
                this.index = i;
                this.dv = true;
            },
            showAddTablet() {
                if(this.temp.maxTablet && this.list.length >= this.temp.maxTablet) {
                    this.$message.warning(`最多只能添加${this.temp.maxTablet}个牌位`)
                } else {
                    this.form = {
                        personList: [],
                        posterityList: []
                    };
                    this.index = null;
                    this.dv = true;
                }
            },
            onClose() {
                this.dv = false;
            },
            dispatchChange() {
                let list = this.list;
                if (this.temp.cat == 1) {
                    list.forEach(item => {
                        item.type = this.type;
                        if(this.userType) {
                            item.user_type = this.userType;
                        }
                    })
                }
                this.$emit("change", list.length > 0 ? [...list] : null);
            },
            confirmTablet() {
                this.$refs.tabletForm.validate(valid => {
                    if (valid) {
                        let form = this.form;
                        form.cat = buddhism.hasRelation({cat: this.temp.cat, type: form.type || this.type}) ? 2 : 1;
                        if(this.index === null) {
                            this.list.push(form);
                        } else {
                            this.$set(this.list, this.index, form);
                        }
                        this.dv = false;
                        this.dispatchChange()
                    } else {
                        this.$message.warning('牌位信息填写有误');
                    }
                });
            },
            modalConfirm() {
                this.$refs.personForm.handleConfirm().then(form => {
                    if(form.birth && form.die && new Date(form.die).getTime() < new Date(form.birth)) {
                        this.$message.warning("卒日期不能小于生日期");
                        return;
                    }
                    let list = this.form[`${this.mt}List`];
                    if(this.mi === null) {
                        list.push(form);
                    } else {
                        this.$set(list, this.mi, form);
                    }
                    // 府上名称自动取第一个阳上姓名的第一个字
                    // const pl = this.form.posterityList && this.form.posterityList.length > 0 ? this.form.posterityList : this.form.personList;
                    // if(pl && pl.length > 0) {
                    //     this.form.username = pl[0].name.substring(0,1);
                    // }
                    this.mv = false;
                });
            },
            init() {
                let value = this.value;
                if (value) {
                    this.list = clone(value);
                } else {
                    this.list = []
                }
            },
            setList(type) {
                if(!this.part) {
                    this.$set(this.form, `${type}List`, []);
                }
            },
            addItem(type) {
                let num = this.tabletConfig[type].num;
                let list = this.form[`${type}List`];
                if (list.length < num) {
                    const item = {};
                    // 所有选择日期的表单项默认为公历
                    const items = this.tabletConfig[`${type}Items`];
                    const keys = items.filter(c => c.slot == 'date').map(c => c.key);
                    keys.forEach(k => item[`${k}DateType`] = 1);
                    list.push(item);
                } else {
                    this.$message.warning(`最多只能添加${num}个`)
                }
            },
            editItem(i, type) {
                let item = this.form[`${type}List`][i];
                this.person = clone(item);
                this.mi = i;
                this.mt = type;
                this.mv = true;
            },
            removeItem(i, type) {
                this.form[`${type}List`].splice(i, 1)
            },
        }
    }
</script>

<style lang="less">
  .buddha-tablet-item {
    padding-bottom: 10px;
    &:not(:last-of-type) {
      margin-bottom: 10px;
      border-bottom: var(--border);
    }
  }
  .buddha-tablet-title {
    line-height: 1.5;
    .txt-btn {
      margin-left: 1em;
    }
  }
  .tablet-info-item {
    display: flex;
    padding: 4px 0;
    line-height: 1.5;
  }
  .buddha-tablet-label {
    min-width: 80px;
    color: @text-color-secondary;
  }
  .buddha-tablet-text {
    flex: 1;
    margin-left: 20px;
    color: @text-color;
    text-align: justify;
  }
  .buddha-text-item {
    display: flex;
    align-items: flex-start;
    gap: 1em;
  }
  .tablet-drawer-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: @component-background;
  }

  .add-tablet-item {
    color: @primary-color;
    cursor: pointer;
  }

  .tablet-person-item {
    position: relative;
    &:not(:first-of-type) {
      margin-top: 8px;
      padding-top: 8px;
      border-top: var(--border);
    }
  }

  .tablet-person-text {
    margin-right: 6px;
  }
  .tablet-person-edit,
  .tablet-person-remove {
    position: absolute;
    top: 11px;
    right: 0;
    width: 18px;
    height: 18px;
    font-size: 18px;
    color: @text-color-secondary;
    cursor: pointer;
  }
  .tablet-person-remove {
    right: 0;
  }
  .tablet-person-edit {
    right: 30px;
  }
  .person-date-show {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 0 11px;
    width: 100%;
    height: 32px;
    border-radius: 0 4px 4px 0;
    border: 1px solid @normal-color;
    line-height: 1.5;
    cursor: pointer;
    &:hover {
      border-color: @primary-5;
    }
    .person-date-icon {
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      margin: auto 0;
      width: 14px;
      height: 14px;
      font-size: 14px;
      line-height: 1;
      color: rgba(0, 0, 0, 0.25);
    }
  }
  .person-date-text {
    flex: 1;
    min-width: 0;
    white-space: nowrap;
  }
  .person-date-place {
    color: #bfbfbf;
  }
</style>
